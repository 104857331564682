import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1550px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionImg10 = styled.div`
  width: 1400px;
  height: 1231px;
  background-color: #f6f6f6;
  background-image: url("/img/section11.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Suggest = styled.img`
  width: 1302px;
  height: 470px;
`;

function Section10() {
  return (
    <Container>
      <SectionImg10>
        {/* <a href="#">
          <Suggest src="/img/suggest.png" />
        </a> */}
      </SectionImg10>
    </Container>
  );
}

export default Section10;
