import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #635a55;
  display: flex;
  justify-content: center;
`;

const SectionImg4 = styled.img`
  width: 1920px;
  height: 1080px;
`;

function Section4() {
  return (
    <Container>
      <SectionImg4 src="/img/section4.svg" />
    </Container>
  );
}

export default Section4;
