import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 413px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
`;

const SectionImg10 = styled.img`
  width: 1920px;
  height: 413px;
`;

function Section10() {
  return (
    <Container>
      <SectionImg10 src="/img/footer.svg" />
    </Container>
  );
}

export default Section10;
