import styled from "styled-components";

const SectionImage = styled.img`
  width: 100vw;
  height: auto;
`;

function SectionM2() {
  return <SectionImage src="/img/m2.svg" />;
}

export default SectionM2;
