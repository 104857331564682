import styled from "styled-components";

const SectionImage = styled.img`
  width: 100vw;
  height: auto;
`;

function SectionM4() {
  return <SectionImage src="/img/m4.svg" />;
}

export default SectionM4;
