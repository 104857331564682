import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #444444;
  display: flex;
  justify-content: center;
`;

const SectionImg1 = styled.img`
  width: 1920px;
  height: 1080px;
`;

function Section1() {
  return (
    <Container>
      <SectionImg1 src="/img/section1.svg" />
    </Container>
  );
}

export default Section1;
