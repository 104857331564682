import styled from "styled-components";

const SectionImage = styled.img`
  width: 100vw;
  height: auto;
`;

function SectionM6() {
  return <SectionImage src="/img/m6.svg" />;
}

export default SectionM6;
