import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
`;

const SectionImg3 = styled.img`
  width: 1920px;
  height: 1080px;
`;

function Section3() {
  return (
    <Container>
      <SectionImg3 src="/img/section3.svg" />
    </Container>
  );
}

export default Section3;
