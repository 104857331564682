import "./App.css";
import Section1 from "./Section1";
import Section10 from "./Section10";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import SectionM1 from "./SectionM1";
import SectionM2 from "./SectionM2";
import SectionM3 from "./SectionM3";
import SectionM4 from "./SectionM4";
import SectionM5 from "./SectionM5";
import SectionM6 from "./SectionM6";
import SectionM7 from "./SectionM7";
import SectionM8 from "./SectionM8";
import SectionM9 from "./SectionM9";
import SectionM10 from "./SectionM10";

export function Pc({ children }) {
  const isPc = useMediaQuery({
    query: "(min-width: 551px)",
  });

  return <>{isPc && children}</>;
}

export function Mobile({ children }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 550px)",
  });

  return <>{isMobile && children}</>;
}

function App() {
  return (
    <>
      <Pc>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Footer />
      </Pc>
      <Mobile>
        <SectionM1 />
        <SectionM2 />
        <SectionM3 />
        <SectionM4 />
        <SectionM5 />
        <SectionM6 />
        <SectionM7 />
        <SectionM8 />
        <SectionM9 />
        <SectionM10 />
      </Mobile>
    </>
  );
}

export default App;
