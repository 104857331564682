import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
`;

const SectionImg9 = styled.div`
  width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  background-image: url("/img/section9.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

const TeamImg1 = styled.div`
  background-image: url("/img/dion.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 480px;
  height: 658px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const TeamBg1 = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  width: 480px;
  height: 658px;
  position: absolute;
`;

const Name1 = styled.div`
  font-size: 24px;
  color: white;
  position: absolute;
  left: 47px;
  top: 56px;
  line-height: 1.3;
`;

const TeamImg2 = styled.div`
  width: 480px;
  height: 658px;
  position: absolute;
  left: 480px;
  bottom: 0;
  background-color: #808080;
`;

const TeamImg3 = styled(TeamImg2)`
  width: 480px;
  height: 658px;
  position: absolute;
  left: 960px;
  bottom: 0;
  background-color: #808080;
`;

const TeamImg4 = styled(TeamImg2)`
  width: 480px;
  height: 658px;
  position: absolute;
  left: 1440px;
  bottom: 0;
  background-color: #808080;
`;

const TeamBg2 = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  width: 480px;
  height: 658px;
  position: absolute;
`;

const Name2 = styled.div`
  font-size: 24px;
  color: white;
  position: absolute;
  left: 47px;
  top: 56px;
  line-height: 1.3;
`;

const Btn1 = styled.img`
  width: 152px;
  height: 47px;
  position: absolute;
  left: 40px;
  bottom: 103px;
`;

const Btn2 = styled.img`
  width: 193px;
  height: 47px;
  position: absolute;
  left: 40px;
  bottom: 40px;
`;

function Section9() {
  return (
    <Container>
      <SectionImg9>
        <TeamImg1>
          <TeamBg1>
            <Name1>
              김동현(Dion, 29)
              <br />
              PM / Developer
            </Name1>
          </TeamBg1>
        </TeamImg1>
        <TeamImg2>
          <TeamBg2>
            <Name2>
              모집예정
              <br />
              UX / UI Designer
            </Name2>
            <a href="#">
              <Btn1 src="/img/team1.png" />
            </a>
            <a href="#">
              <Btn2 src="/img/team2.png" />
            </a>
          </TeamBg2>
        </TeamImg2>
        <TeamImg3>
          <TeamBg2>
            <Name2>
              모집예정
              <br />
              FullStack Developer
            </Name2>
            <a href="#">
              <Btn1 src="/img/team1.png" />
            </a>
            <a href="#">
              <Btn2 src="/img/team2.png" />
            </a>
          </TeamBg2>
        </TeamImg3>
        <TeamImg4>
          <TeamBg2>
            <Name2>
              모집예정
              <br />
              Marketer
            </Name2>
            <a href="#">
              <Btn1 src="/img/team1.png" />
            </a>
            <a href="#">
              <Btn2 src="/img/team2.png" />
            </a>
          </TeamBg2>
        </TeamImg4>
      </SectionImg9>
    </Container>
  );
}

export default Section9;
