import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
`;

const SectionImg5 = styled.img`
  width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
`;

function Section5() {
  return (
    <Container>
      <SectionImg5 src="/img/section5.svg" />
    </Container>
  );
}

export default Section5;
