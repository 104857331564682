import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
`;

const SectionImg7 = styled.div`
  width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  background-image: url("/img/section7.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

const Android1 = styled.img`
  width: 102.35px;
  height: 29px;
  bottom: 225px;
  left: 329px;
  position: absolute;
  cursor: pointer;
`;

const Android2 = styled(Android1)`
  left: 820px;
`;

const Android3 = styled(Android1)`
  left: 1311px;
`;

const Ios1 = styled.img`
  width: 102.35px;
  height: 29px;
  bottom: 225px;
  left: 442.76px;
  position: absolute;
  cursor: pointer;
`;

const Ios2 = styled(Ios1)`
  left: 933.76px;
`;

const Ios3 = styled(Ios1)`
  left: 1424.76px;
`;

const Btn1 = styled.img`
  width: 475px;
  height: 72px;
  bottom: 130px;
  left: 288px;
  position: absolute;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  cursor: pointer;
`;

const Btn2 = styled(Btn1)`
  left: 780px;
`;

const Btn3 = styled(Btn1)`
  left: 1272px;
`;

function Section7() {
  return (
    <Container>
      <SectionImg7>
        <Android1 src="/img/android.png" />
        <Ios1 src="/img/ios.png" />
        <Btn1 src="/img/buybtn.png" />
        <Android2 src="/img/android.png" />
        <Ios2 src="/img/ios.png" />
        <Btn2 src="/img/buybtn.png" />
        <Android3 src="/img/android.png" />
        <Ios3 src="/img/ios.png" />
        <Btn3 src="/img/buybtn.png" />
      </SectionImg7>
    </Container>
  );
}

export default Section7;
