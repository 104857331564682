import styled from "styled-components";

const SectionImage = styled.img`
  width: 100vw;
  height: auto;
`;

function SectionM8() {
  return <SectionImage src="/img/m8.svg" />;
}

export default SectionM8;
