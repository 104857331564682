import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  min-width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
`;

const SectionImg8 = styled.div`
  width: 1920px;
  height: 1080px;
  background-color: #f6f6f6;
  background-image: url("/img/section8.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

const Idea1 = styled.img`
  position: absolute;
  cursor: pointer;
  width: 380px;
  height: 330px;
  left: 176px;
  bottom: 280px;
`;

const Idea2 = styled(Idea1)`
  left: 572px;
`;

const Idea3 = styled(Idea1)`
  left: 968px;
`;

const Idea4 = styled(Idea1)`
  left: 1364px;
`;

function Section8() {
  return (
    <Container>
      <SectionImg8>
        <a
          href="http://wiki1.kr/index.php/%ec%b2%b4%ec%9d%b8%ed%94%8c%eb%a3%a8%ec%96%b8%ec%84%9c"
          target="_blank"
        >
          <Idea1 src="/img/idea1.png" />
        </a>
        <a href="https://kmong.com/gig/426892" target="_blank">
          <Idea2 src="/img/idea2.png" />
        </a>
        <a href="https://kmong.com/gig/525152" target="_blank">
          <Idea3 src="/img/idea3.png" />
        </a>
        <a href="https://kmong.com/gig/525158" target="_blank">
          <Idea4 src="/img/idea4.png" />
        </a>
      </SectionImg8>
    </Container>
  );
}

export default Section8;
