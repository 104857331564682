import styled from "styled-components";

const SectionImage = styled.img`
  width: 100vw;
  height: auto;
`;

function SectionM3() {
  return <SectionImage src="/img/m3.svg" />;
}

export default SectionM3;
